<template>
  <div
    class="product align-left w-100 px5 pb8 pt5"
    :class="[{'comparable-tile': isComparableTile}, {'hit-style': (product.hit_of_the_week || product.hit_of_the_month) && !isMainPage }]"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true
    }"
  >
    <div v-if="isComparableTile" @click="removeFromList('compare')" class="close-comparable-tile pointer">
      <close-svg width="18px" height="100%" color="#828282" />
    </div>
    <router-link class="no-underline main-link" :event="!preventClick ? 'click' : 'none'" :to="productLink" data-testid="productLink">
      <div class="flex" :class="[isRowMode ? 'product-link-row' : 'product-link', isComparableTile ? 'inner-tile' : '']">
        <div
          class="product-image relative p0"
          :class="[isRowMode ? 'col-xs-4' : 'col-xs-12']"
        >
          <div class="absolute label">
            <span v-show="product.bestseller" class="tile-label uppercase align-center cl-white bg-cl-alternative">
              {{ $t('bestseller') }}
            </span>
            <span v-show="isNew" class="tile-label uppercase align-center cl-white bg-cl-forest-green">
              {{ $t('new') }}
            </span>
            <span v-show="product.special_price" class="tile-label uppercase align-center cl-white bg-cl-orange">
              {{ $t('special price') }}
            </span>
            <span v-show="product.hit_of_the_week" class="tile-label uppercase align-center cl-white bg-cl-buccaneer">
              {{ $t('hit_of_the_week') }}
            </span>
            <span v-show="product.hit_of_the_month" class="tile-label uppercase align-center cl-white bg-cl-buccaneer">
              {{ $t('hit_of_the_month') }}
            </span>
            <span v-show="product.free_valves" class="tile-label uppercase align-center cl-white bg-cl-forest-green">
              {{ $t('valves_free') }}
            </span>
            <span v-show="product.hit_price" class="tile-label uppercase align-center cl-white bg-cl-orange">
              {{ $t('hit_price') }}
            </span>
          </div>
          <product-image
            class="product-image__content"
            :image="!seen ? loadingThumbnailObj : thumbnailObj"
            :alt="product.name | htmlDecode"
            data-testid="productImage"
          />
        </div>
        <div class="tile-content" :class="[{'col-md-5 col-xs-6': isRowMode}]">
          <p v-show="getLabelforValue('manufacturer', product.manufacturer)" class="mb0 mt5 fs-medium-small">
            {{ getLabelforValue('manufacturer', product.manufacturer) }}
          </p>
          <p class="my0 cl-mine-black fs-medium product__name" v-if="!onlyImage">
            {{ product.name | htmlDecode }}
          </p>
          <div
            class="mr5 cl-secondary my5"
            v-if="product.special_price && parseFloat(product.original_price_incl_tax) > 0 && !onlyImage"
          >
            <span v-if="product.special_price" class="uppercase align-center cl-white bg-cl-buccaneer px5 py2 fs-medium-small">
              -{{ countedDiscount }}
            </span>
            <span class="price-original cl-matterhorn ml10">
              od: {{ product.original_price_incl_tax | price }}
            </span>
          </div>
          <p
            class="lh30 h3 mt0 mb5 product-tile-price"
            :class="product.special_price && parseFloat(product.original_price_incl_tax) > 0 ? 'cl-buccaneer' : 'cl-mine-black mt20'"
            v-if="(!onlyImage && minPrice.price_incl_tax) && !linkActive"
          >
            <span class="fs-medium-small cl-matterhorn">od: </span> {{ minPrice.price_incl_tax | price }}
          </p>
          <p
            class="lh30 h3 mt0 mb5 product-tile-price"
            :class="product.special_price && parseFloat(product.original_price_incl_tax) > 0 ? 'cl-buccaneer' : 'cl-mine-black'"
            v-else-if="!onlyImage && linkActive"
          >
            {{ product.final_price | price }}
          </p>
          <div class="row">
            <div class="col-xs-12 cl-mine-black fs-medium-small mt5" :class="{'col-xl-7 pr0': reviewCount >= 1}">
              <template v-if="product.delivery_time <= 3 || isEcommerceProduct">
                Dostępność: <span class="cl-forest-green"> w magazynie</span>
              </template>
              <template v-else>
                Czas realizacji {{ product.delivery_time }} dni
              </template>
            </div>
            <div class="col-xs-12 col-xl-5 star-rating flex middle-xs between-xs wrap">
              <div class="flex middle-xs pb5">
                <i class="cl-yellow h5" v-if="reviewStar >= 1">&#9733;</i>
                <i class="cl-yellow h5" v-if="reviewStar >= 2">&#9733;</i>
                <i class="cl-yellow h5" v-if="reviewStar >= 3">&#9733;</i>
                <i class="cl-yellow h5" v-if="reviewStar >= 4">&#9733;</i>
                <i class="cl-yellow h5" v-if="reviewStar >= 5">&#9733;</i>
                <span class="cl-yellow fs13 weight-300">
                  <template v-if="reviewCount >= 1">({{ reviewCount }})</template>
                </span>
              </div>
            </div>
          </div>
          <div v-show="isRowMode" class="hidden-xs mt5">
            <p class="m0 cl-mine-black lh20 flex middle-xs fs-medium-small">
              <i class="icon-delivery pr3 fs-medium" />
              Darmowy transport od 5000zł
            </p>
          </div>
          <div v-if="isComparableTile">
            <div @click.prevent class="center-flex-text py8 hidden-xs">
              <add-to-cart-with-wishlist
                :product="product"
                :product-link="productLink"
                class="row col-xs-12 p0"
              />
            </div>
            <div class="visible-xs row">
              <div class="col-xs-12 p0 center-flex-text bg-cl-primary-orange border-rad py3 mt5" @click="addToCart(product)">
                <i class="icon-buy cl-white icon-size" />
                <p class="m0 pl10 cl-white uppercase h6">
                  {{ $t('Do koszyka') }}
                </p>
              </div>
              <div @click.prevent="isOnWishlist ? removeProductFromWhishList(product) : addProductToWhishlist(product)"
                   class="col-xs-12 p0 center-flex-text brdr-1 brdr-cl-primary-orange py3 my5 border-rad"
              >
                <i class="icon-schowek cl-primary-orange icon-size-white" />
                <span class="weight-300 cl-orange h6 uppercase">
                  <template v-if="!isOnWishlist">
                    {{ $t('Do schowka') }}
                  </template>
                  <template v-else>
                    {{ $t('Usuń z schowka') }}
                  </template>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-show="isRowMode" class="tile-action col-md-3 col-xs-2 p0 flex column around-xs">
          <div class="row-actions-btn">
            <i class="icon-buy cl-primary-orange icon-size" />
            <span class="hidden-xs pl5 uppercase cl-primary-orange">Do koszyka</span>
          </div>
          <div @click.prevent="isOnWishlist ? removeProductFromWhishList(product) : addProductToWhishlist(product)" class="row-actions-btn pointer">
            <i class="icon-schowek cl-primary-orange icon-size" />
            <span class="hidden-xs pl5 cl-primary-orange uppercase">
              <template v-if="!isOnWishlist">
                {{ $t('Do schowka') }}
              </template>
              <template v-else>
                {{ $t('Usuń z schowka') }}
              </template>
            </span>
          </div>
          <div class="hidden-xs">
            <div
              @click.prevent="isOnCompare ? removeFromList('compare') : addToList('compare')"
              data-testid="addToCompare"
              class="uppercase button bg-cl-white cl-orange row-actions-btn"
            >
              <i class="icon-compare cl-primary-orange icon-size" />
              <template v-if="!isOnCompare">
                <span class="pl5 ">Porównaj</span>
              </template>
              <template v-else>
                <span class="pl5">Usuń</span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!isComparableTile && !isRowMode && !isNotShowHover" class="expand__block mt7 hidden-xs-and-tablet">
        <div class="mb5">
          <p class="dostawa-info m0 pl5 cl-mine-black lh20 flex middle-xs">
            <i class="icon-delivery pr3 fs-medium-small" />
            Darmowy transport od 5000zł
          </p>
        </div>
        <div class="uppercase button bg-cl-orange cl-white button__add-to-cart">
          <i class="pl15 icon-buy cl-white icon-size" />
          <span class="button__center">Do koszyka</span>
        </div>
        <div
          @click.prevent="isOnCompare ? removeFromList('compare') : addToList('compare')"
          data-testid="addToCompare"
          class="uppercase button bg-cl-white cl-orange button__add-to-cart"
        >
          <i class="pl15 icon-compare cl-primary-orange icon-size" />
          <template v-if="!isOnCompare">
            <span class="button__center">Porównaj</span>
          </template>
          <template v-else>
            <span class="button__center">Usuń</span>
          </template>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import { getValueFor } from '@vue-storefront/core/helpers'
import { notifications } from '@vue-storefront/core/modules/cart/helpers'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
// import ColorimageSelectorByRelated from './ColorImageSelectorByRelated'
import config from 'config'
import Vue from 'vue'
import { IsOnWishlist } from 'src/modules/vsf-magento-wishlist/components/IsOnWishlist'
import { AddToWishlist } from 'src/modules/vsf-magento-wishlist/components/AddToWishlist'
import { RemoveFromWishlist } from 'src/modules/vsf-magento-wishlist/components/RemoveFromWishlist'
import { AddToCompare } from '@vue-storefront/core/modules/compare/components/AddToCompare.ts'
import { CompareProduct } from '@vue-storefront/core/modules/compare/components/Product.ts'
import ProductImage from './ProductImage'
import i18n from '@vue-storefront/i18n'
import AddToCartWithWishlist from './AddToCartWithWishlist.vue'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'
import getLabelMixin from 'theme/mixins/getLabelMixin.ts'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  mixins: [ProductTile, AddToCompare, CompareProduct, IsOnWishlist, getLabelMixin, RemoveFromWishlist, AddToWishlist],
  components: {
    ProductImage,
    AddToCartWithWishlist,
    // ColorimageSelectorByRelated,
    CloseSvg
  },
  props: {
    isMainPage: {
      type: Boolean,
      default: false
    },
    onlyImage: {
      type: Boolean,
      default: false
    },
    isNotShowHover: {
      type: Boolean,
      default: false
    },
    isComparableTile: {
      type: Boolean,
      default: false
    },
    linkActive: {
      type: Boolean,
      default: false
    },
    position: {
      type: Number,
      default: 1
    },
    reviews: {
      type: Array,
      default: () => []
    },
    isRowMode: {
      type: Boolean,
      required: false,
      default: false
    },
    preventClick: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      seen: false,
      loadingThumbnailObj: {
        src: '/assets/placeholder.jpg',
        loading: '/assets/placeholder.jpg'
      }
    }
  },
  computed: {
    isNew () {
      const currentDate = new Date()
      // eslint-disable-next-line no-trailing-spaces
      let expireDate = this.product.news_to_date 
        ? Date.parse(this.product.news_to_date)
        : 'none'
      return ((expireDate !== 'none') && (currentDate.getTime() < expireDate))
    },
    valueForYes () {
      return getValueFor('ecommerce', 'yes')
    },
    valueForBoth () {
      return getValueFor('ecommerce', 'both')
    },
    minPrice () {
      let configurable_children = this.product.configurable_children
      if (configurable_children && configurable_children.length) {
        configurable_children = configurable_children
          .filter(child => child.price_incl_tax && child.stock.is_in_stock === true)
          .reduce((prev, curr) => {
            return (prev.price_incl_tax < curr.price_incl_tax)
              ? prev
              : curr
          }, 0)
      } else {
        return null
      }
      return configurable_children
    },
    isEcommerceProduct () {
      return this.product.configurable_children
        ? this.product.configurable_children.some(element => {
          return element.stock
            ? (this.product.ecommerce === this.valueForYes && element.stock.qty > 0) || (this.product.ecommerce === this.valueForBoth && element.stock.qty > 0)
            : false
        })
        : false
    },
    thumbnailObj () {
      return {
        src: this.thumbnail,
        loading: this.thumbnail
      }
    },
    reviewCount () {
      return this.reviews && this.reviews.length
        ? this.reviews.length
        : 0
    },
    reviewStar () {
      let reviewStar = this.reviews && this.reviews.length ? this.reviews.map((item) => item.ratings && item.ratings.length ? item.ratings[0].value : 0).filter((x) => x) : null
      return reviewStar && reviewStar.length
        ? reviewStar.reduce((a, b, index) => a + b, 0) / reviewStar.length
        : null
    },
    countedDiscount () {
      return this.minPrice && (100 - Math.ceil((this.minPrice.price_incl_tax / this.minPrice.original_price_incl_tax) * 100)) + '%'
    }
  },
  methods: {
    async addToCart (product) {
      try {
        const diffLog = await this.$store.dispatch('cart/addItem', { productToAdd: product })
        if (diffLog.clientNotifications.length === 0) {
          this.$router.push(this.productLinkPush(product))
        }
        diffLog.clientNotifications.forEach(notificationData => {
          this.notifyUser(notificationData)
        })
      } catch (message) {
        this.notifyUser(notifications.createNotification({ type: 'error', message }))
      }
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData, { root: true })
      this.$store.commit('ui/setAddToCart', false)
    },
    productLinkPush (product) {
      return formatProductLink(product, currentStoreView().storeCode)
    },
    addProductToWhishlist (product) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: `Produkt ${product.name} został dodany do schowka!`,
        action1: { label: i18n.t('OK') }
      }, { root: true })
      this.addToWishlist(product)
    },
    removeProductFromWhishList (product) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: `Produkt ${product.name} został usunięty ze schowka!`,
        action1: { label: i18n.t('OK') }
      }, { root: true })
      this.removeFromWishlist(product)
    },
    addToList (list) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: `Produkt ${this.product.name} został dodany do porównania!`,
        action1: { label: i18n.t('OK') },
        action2: { label: i18n.t('Przejdź do porównania'), action: 'toCompare' }
      }, { root: true })
      // Method renamed to 'addToCompare(product)', product is an Object
      AddToCompare.methods.addToCompare.call(this, this.product)
    },
    removeFromList (list) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: `Produkt ${this.product.name} został usunięty ze porównania!`,
        action1: { label: i18n.t('OK') }
      }, { root: true })
      // Method renamed to 'removeFromCompare(product)', product is an Object
      CompareProduct.methods.removeFromCompare.call(this, this.product)
    },
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (isVisible) {
        if (config.products.configurableChildrenStockPrefetchDynamic && rootStore.products.filterUnavailableVariants) {
          const skus = [this.product.sku]
          if (this.product.type_id === 'configurable' && this.product.configurable_children && this.product.configurable_children.length > 0) {
            for (const confChild of this.product.configurable_children) {
              const cachedItem = rootStore.state.stock.cache[confChild.id]
              if (typeof cachedItem === 'undefined' || cachedItem === null) {
                skus.push(confChild.sku)
              }
            }
            if (skus.length > 0) {
              rootStore.dispatch('stock/list', { skus: skus }) // store it in the cache
            }
          }
        }
      }
      if (isVisible && !this.seen) {
        if (window.dataLayer) {
          let price = this.product.special_price_incl_tax !== 0 ? this.product.special_price_incl_tax : this.product.price_incl_tax
          price = Math.abs(parseFloat(price)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '')
          let categories = ''
          this.product.category.map(cat => {
            categories = categories + '/' + cat.name
          })
          categories = categories.substr(1)
          Vue.gtm.trackEvent({
            event: 'uaevent',
            eventCategory: 'Ecommerce',
            eventAction: 'Product Impressions',
            ecommerce: {
              currencyCode: 'PLN',
              impressions: [{
                name: this.product.name,
                id: this.product.parentSku,
                price: price,
                brand: this.getLabelforValue('manufacturer', this.product.manufacturer) ? this.getLabelforValue('manufacturer', this.product.manufacturer) : '',
                category: categories,
                position: this.position + 1
              }]
            }
          })
          this.seen = true
        }
      }
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$bg-secondary: color(secondary, $colors-background);
$border-secondary: color(secondary, $colors-border);
$color-white: color(white);

.icon-size-white {
  font-size: 22px;
  line-height: 30px;
}
.icon-size {
  font-size: 28px;
  line-height: 37px;
  @media (max-width: 992px) {
    font-size: 20px;
  }
}
.main-link {
  position: initial;
}
.row-actions-btn {
  @media (min-width: 767px) {
    display: flex;
    align-items: center;
    border: 1px solid #ff8100;
    justify-content: center;
    border-radius: 20px;
    padding: 5px 0px;
  }
}
.dostawa-info {
  font-size: 14px;
  @media (max-width: 1275px) {
    font-size: 13px;
  }
  @media (max-width: 1200px) {
    font-size: 12px;
  }
}
.d-color {
  color: #d3d3d3;
}
.comparable-tile {
  padding: 0 !important;
  border: none !important;
  .border-rad {
    border-radius: 20px;
  }
  .close-comparable-tile {
    position: absolute;
    right: 10px;
    font-size: 26px;
    top: 0;
    z-index: 1;
  }
  .inner-tile {
    background: white;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px 8px 0 8px;
    box-shadow: 0px 3px 6px #00000029;
  }
}
.tile-content {
  @media (max-width: 767px) {
    padding: 0 8px;
  }
}
.product-link-row {
  flex-direction: row;
  .tile-content {
    @media (max-width: 767px) {
      padding: 0 8px;
    }
    .product__name {
      @media (max-width: 767px) {
        font-size: 15px;
        margin: 0;
        height: 60px !important;
      }
    }
    .product-tile-price {
      @media (max-width: 767px) {
        font-size: 18px;
        margin: 5px 0;
      }
    }
  }
}
.product-link {
  flex-direction: column;
}
.tile-label {
  margin-top: 2px;
  z-index: 1;
  font-size: 14px;
  padding: 3px 5px;
  @media (max-width: 767px) {
    font-size: 12px;
    padding: 2px 2px;
  }
}
.product-attr {
  display: none;
  animation: fadeIn 0.3s;
  &:hover {
    margin: 20px 0;
    padding: 0;
    display: block;
  }
}
.fs13 {
  font-size: 13px;
}
.button {
  @media (max-width: 992px) {
    font-size: 12px;
  }
  &__add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-right: 30px;
  }
  &__center {
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
  }
}
.product {
  position: relative;
  &.hit-style {
    box-shadow: 0 0 0px 4px #A82540;
    &:hover {
      box-shadow: 0 0 0px 4px #A82540;
    }
    .expand__block {
      opacity: 0;
      border-bottom: 4px solid #A82540;
      border-left: 4px solid #A82540;
      border-right: 4px solid #A82540;
      box-shadow: none !important;
      left: -4px;
    }
  }
  &__name {
    // height: 60px;
    overflow: hidden;
  }
  .label {
    width: 120px;
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      width: 110px;
    }
  }
  .expand__block {
    background-color: white;
    backface-visibility: hidden;
    height: 0;
    position: absolute;
    overflow: hidden;
    z-index: 3;
    width: 100%;
    left: 0px;
    transition: height .2s ease-out;
  }
  .expand__block span{
    width: 100%;
  }
  &:hover {
    box-shadow: 0 0 0px 1px #e0e0e0;
    .expand__block {
      height: 105px;
      opacity: 1;
      @media (max-width: 1200px) {
        height: 125px;
      }
      @media (max-width: 992px) {
        height: 143px;
      }
      box-shadow: 0px 1px 0px 1px #e0e0e0;
    }
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    border: none;
    padding-bottom: 10px;
    &:hover {
      border: none;
    }
  }
}

.price-original {
  text-decoration: line-through;
}

.product-image{
  overflow: hidden;
  // width:100%;
  // height: 100%;
  max-height: 300px;
  &__content{
    padding-bottom: calc(300% / (257 / 100));
    mix-blend-mode: darken;
    opacity: .8;
    transform: scale(1);
    will-change: transform;
    transition: .3s opacity $motion-main, .3s transform $motion-main;
    @media (min-width: 768px) {
      padding-bottom: calc(208% / (168 / 100));
    }
    @media (min-width: 1200px) {
      padding-bottom: calc(300% / (276 / 100));
    }
  }
}
</style>
